import { Component, HostListener } from '@angular/core';
import { environment } from '@environments/environment';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss'],
})
export class AppComponent {

  @HostListener('window:message', ['$event']) async onPostMessage(event) {
    const id = event?.data?.id;
    if (id === 'tokenExpired') {
      window.location.href = `${environment.authUrl}/checklogin?next_url=${encodeURIComponent(window.location.href)}`;
    }
  }

}
