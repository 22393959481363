export const environment = {
  project: 'webapp',
  production: true,

  envDomain: "beta",
  COOKIE_LOCALE_DOMAIN: "netatmo.com",
  LEGALS_URL: "https://legals.netatmo.com/",

  appUrl: "https://homebeta.netatmo.com/",
  apiUrl: "https://appbeta.netatmo.net/api/",
  syncApiUrl: "https://appbeta.netatmo.net/syncapi/v1/",
  authUrl: "https://authbeta.netatmo.com/",
  logoutUrl: "https://authbeta.netatmo.com/logout",
  cookiePrefix: "betanetatmocom",
  cookieDomain: ".netatmo.com",
  helpcenterUrl: "https://helpcenter.netatmo.com/hc/",
  settingsLegrandUrl: "https://settingsbeta.netatmo.com/legrand/#/",
  settingsEnergyUrl: "https://settingsbeta.netatmo.com/energy/",
  settingsWeatherUrl: "https://settingsbeta.netatmo.com/weather/",
  myUrl: "https://mybeta.netatmo.com",
  myAccountUrl: "https://myconnectedaccountbeta.netatmo.com/",
  langsUrl: "/langs/",
  termsAndCondtionsUrl: "https://viewbeta.netatmo.com/",

  webPusherUrl: "wss://appbeta-ws.netatmo.net/ws/",
  webRTCSocketUrl: "wss://appbeta-ws.netatmo.net/appws/",

  turnUrl: "https://app-turnbeta.netatmo.net/api/",
  env: "beta",
  weatherMapUrl: "https://weathermapbeta.netatmo.com",
  shopUrl : "https://shop.netatmo.com/"
};
